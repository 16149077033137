.Jumbotron{
    background-color: #fff;
    padding: 4rem 2rem;
    margin-bottom: 2rem;
    border-radius: .3rem;
    width: 90%;
    margin: 0 auto;
    /* border-style: dotted; */
}
.Jumbotron-image{
    max-width: 100%;
}
.Jumbotron-description, .Kristik-description, .Motif-description{
    padding: 100px 20px;
}
.Motif-content{
    padding-bottom: 50px;
}
.Motif-description{
    padding: 50px;
}
.Work{
    text-align: left;
    padding: 50px 0;
}

.Work-content img{
    width: 40px;
}
.Service{
    text-align: left;
    padding-top: 100px;
}
.Service-title{
    text-align: center;
    padding-bottom: 30px;
}
.Service-content{
    padding-bottom: 20px;
}
.Service-content button{
    text-transform: uppercase;
}
.Button-d{
    font-weight: bolder;
}

.Mobile{
    background-color: rgba(239,240,242,1.0);
    text-align: left;
}
.google img{
    background-color: #fff;
    padding: 10px 20px;
    border-radius: .5em;
    max-width: 150px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.Testimony{
    background-color: rgba(243, 243, 243, 0.932);

}
.Testimony img{
    max-width: 50px;
}
.Newsletter{
    padding: 100px;
}

.carousel-inner img {
    width: 100%;
    height: 100%;
}
.masto{
    /* background-color: red; */
    background-image: url("/images/lain-lain.png");
    background-repeat: no-repeat, repeat;
    background-size: 100%;
    height: 340px;
}
.masto button{
    margin-top: -100px;
}