.btn-checkout {
    background-color: #333;
    color: #fff;
    border: 1px solid #333;
    border-radius: 5em!important;
    padding: 0.45rem 2.5rem!important;
}

.btn-circle {
    border: 1px solid #000!important;
    width: 25px;
    height: 25px;
    line-height: 0!important;
    text-align: center;
    font-size: 14px!important;
    border-radius: 12.5px;
}

.nav li.active, .navbar-nav li.active {
    background: #f4f7f6;
    border-bottom: 1px solid #E6EAEA;
    -webkit-transition: background 0.3s ease-in-out, border 0.3s ease-in-out, color 0.3s ease-in-out;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out, color 0.3s ease-in-out;
}
