.ForgotPassword {
  text-align: left;
  padding: 1em;
  margin: 1em;
  border: 2px solid #d3d3d3;
  border-radius: .5em;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  margin-top: 100px;
}

.form {
  padding: 1em;
}

label {
  display: flex;
  font-weight: 600;
}

button {
    justify-content: flex-end;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}

.ForgotPassword-title {
  font-size: 1.5em;
  text-align: center;
}

.ForgotPassword-intro {
  font-size: large;
}

@keyframes ForgotPassword-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
