.list-group-item {
    padding: 0 10px 0 10px!important;
}

.list-group-unbordered > .list-group-item {
    border: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
}

.pull-right {
    float: right!important;
}

.pull-left {
    float: left!important;
}

.cart .media img {
    width: 125px;
    height: 125px;
}

.checkout .media img {
    width: 80px;
    height: 80px;
}

.cart .media .media-body .description {
    padding: 10px 0 10px 2rem;
    margin-top: 20px;
}

.checkout .media .description {
    padding: 10px;
}

.cart .media .media-body h4 {
    font-size: 18px;
}

.checkout .media .media-body h4 {
    font-size: 16px;
}

.checkout .list-group-item {
    border: 0;
}

.cart .media .media-body .cart-price {
    color: #e64848;
}

.cart .media .media-body .cart-desc {
    color: #636161;
}

.cart .media .media-body .action {
    height: 125px;
    display:flex;          /* make children flex too for centering */
    align-items:center;    /* vertical center */
    justify-content:center; /* horizontal center */
}

.cart .btn-group .quantity {
    padding: 0 18px;
    font-size: 14px;
}

.cart .cart-remove {
    position: absolute;
    top: 6px;
    right:20px;
}

.subtotal {
    padding: 5px 20px;
    font-size: 16px;
    font-weight: bold;
}


.Ditenun-list-cart .collapse {
    margin-bottom: 10px;
    width: 100%;
}


.order-detail {
    border-top: 1px solid #dcd8da;
    margin-top: 10px;
    margin-left: 0!important;
    margin-right: 0!important;
}

.order-detail .list-solid {
    margin-bottom: 10px!important;
}

.order-detail h4 {
    padding: 0!important;
    margin-top: 15px;
}

.order-detail .total-order {
    font-size: 15px;
    font-weight: 700;
}
