/* To bar notifications */

.notifications-tr {
    top: 0;
	left: 0;
	width: 100%;
    position: fixed;
    line-height: 1.4;
    opacity: 0.7;
    z-index: 1060;
    color: rgba(250,251,255,0.95);
    pointer-events: none;
    font-size: 90%;
    box-shadow: inset 4.5em 0 rgba(0,0,0,0.6);
}

.notification {
    padding: 22px;
}

.notifications-tr .notification-error {
    background: rgba(191,0,0,0.95);
}

.notification-hidden {
    display: none!important;
}

.notification-visible{
    display: block!important;
}

.notifications-tr .notification-success {
    background: rgba(26,178,104,0.95);
}

.notifications-tr .notification-dismiss {
    width: 20px;
	height: 20px;
	position: absolute;
    top: 40%;
	right: 20px;
    font-size: 90%;
	overflow: hidden;
	cursor: pointer;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.notifications-tr .notification-message {
    padding: 0 3.2em;
    font-size: 1.2em;
    display: inline-block;
}
