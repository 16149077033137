.Product-row{
    padding: 0 100px;
}
.Product-row-menu{
    padding-top: 50px;
    text-transform: uppercase;
}
.Product-row-menu a{
    text-transform: uppercase;
    padding: 0 10px;
    letter-spacing: .01em;
}
.Product-row-menu a:hover{
    text-transform: uppercase;
}
.Category-row-menu button{
    text-transform: uppercase;
    background-color: #fff;
    color: #333;
    border: none;
}
.Product-column {
    padding: 10px;
}
.Product-content{
    text-transform: uppercase;
}
.Product-content a, .Product-content a:hover{
    color: black;
    text-decoration: none;
}
.Product-content .Product-description p{
    color: black;
    padding: 10px;
    font-size: 14px;
    margin: 0 0 5px 0;
}

.Product-detail .Product-description h1 {
    font-size: 1.8rem;
    text-align: left;
    text-transform: uppercase;
}

.Product-detail .Product-description .price {
    margin-bottom: 10px;
}

.Product-detail .Product-description p{
    text-transform: capitalize;
}
/* Style the images inside the grid */
.Product-column img {
    opacity: 1;
    cursor: pointer;
    width: 504px;
}

.Product-column img:hover {
    opacity: .9;
}

/* Clear floats after the columns */
.Product-row:after {
    content: "";
    display: table;
    clear: both;
}

/* The expanding image container (positioning is needed to position the close button and the text) */
.Product-container {
    position: relative;
    display: none;
}
.Product-category{
    text-align: left;
}
.Product-category a{
    color: black;
}
.Product-category a:hover{
    color: black;
    font-weight: bold;
    text-decoration: none
}

.Product-category-nav {
    overflow: hidden;
    background-color: #fff;
}

.Product-category-nav a {
    float: left;
    color: black;
    text-align: center;
    padding: 10px 15px;
    text-decoration: none;
    font-size: 17px;
}

.Product-category-nav a:hover {
    background-color: #ddd;
    color: black;
    text-decoration: none;
}

.Product-category-nav a.active {
    background-color: #333;
    color: white;
}

.Product-search {
float: right;
}

.list-solid {
    margin-bottom: 20px !important;
}
.list-solid li {
    border: 0;
}

.list-solid .list-group-item {
    padding-left: 0!important;
}
