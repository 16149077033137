.container {
    margin-top: 25px;
}

.upload .box-body {
    min-height: 540px;
    padding-bottom: 0;
}

.upload .box-body .icon-large {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150px;
    height: 150px;
    margin-top: -75px; /* Half the height */
    margin-left: -75px; /* Half the width */
    fill: #b9b9b9;
}

.upload .box-footer {
    padding-top: 0;
    border-top: 0;
}

.upload .box-footer button {
    margin: 0;
    width: 100%;
}

.upload img, .random img {
    height: auto;
    max-width: 100%;
}

.random img {
    max-width: 640px;
}

.list .box-header h4 {
    letter-spacing: 0.05em;
    text-transform: none;
}

.list img {
    max-width: 245px;
    margin-bottom: 5px;
}
