h1, h2, h3, h4, h5{
    letter-spacing: .08em;
    text-transform: capitalize;
}
h1{
    text-align: center;
    padding-bottom: 20px;
}
.avatar {
    vertical-align: middle;
    max-width: 20px;
    max-height: 20px;
    border-radius: 50%;
}
.img_profile {
    max-width: 100px;
    max-height: 100px;
    padding: 10px;
}
/* .active {
    color: green;
} */
.link1, .button1, .button2, .button3, .button4, .button5, a, button{
    width: auto;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    font-size: .8rem;
    letter-spacing: .05em;
    color: #333;
    padding: 10px;
    margin: 2px;
}
.link1, .button1{
    background-color: #333;
    color: #fff;
    border: 1px solid #333;
    border-radius: 5em;
}
.link2, .button2{
    background-color: #fff;
    color: #333;
    border: 1px solid #333;
    border-radius: 5em;
}
.link3, .button3{
    background-color: #333;
    color: #fff;
    border: 1px solid #333;
    border-radius: 0;
}
.link4, .button4{
    background-color: #fff;
    color: #333;
    border: 1px solid #333;
    border-radius: 0;
}
.link5, .button5{
    background-color: #fff;
    color: #333;
    border: 1px solid #fff;
    border-radius: 0;
}
.Ditenun a{
    width: auto;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    font-size: .8rem;
    letter-spacing: .2em;
}
.Ditenun button{
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    font-size: .8rem;
    letter-spacing: .2em;
    border: 1px solid #333;
    border-radius: 5em;
}
.Ditenun a:hover{
    background-color: #fff;
    color: #333;
    text-decoration: none;
}
.Ditenun2 a{
    background-color:rgba(33, 33, 33, 0.7); ;
    color: #fff;
    padding: 10px 20px;
    font-weight: bolder;
    text-decoration: none;
    text-transform: uppercase;
    font-size: .8rem;
    letter-spacing: .2em;
    border-radius: .2em;
}
.Ditenun2 a:hover{
    background-color: #fff;
    color: #333;
    text-decoration: none;
}
.Ditenun-button{
    width: auto;
    overflow: visible;
    color: #fff;
    margin: 0;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: .7rem;
    border-radius: 5em;
    letter-spacing: .2em;
}

.Header{
    background-color: #ffffff;
    width: 90%;
    margin: 0 auto;
}
.Header a{
    font-weight: 500;
    font-size: 14px;
    color: black;
    text-transform: uppercase;
}
.Button-login button{
    font-weight: 500;
    font-size: 15px;
    color: rgba(0,0,0,.5);
    border: 0;
    background-color: #fff;
    text-transform: uppercase;
}
.Header ul li{
    padding: 0 20px
}
.Header a:active{
    font-weight: bolder;
}
.Header-logo{
    max-width: 60px;
    max-height: 40px;
}
.Header-name{
    background-color: rgba(255,255,255,0.0);
    opacity: .8;
    font-size: 18.0px;
    color: rgba(4,75,123,1.0);
    text-align: left;
    letter-spacing: 1.94px;
    line-height: 25.0px;
    padding-left: 20px;
    font-weight: bolder;
}
.Footer{
    background-color: #3a3a3a;
    color: #f7f7f7;
    text-align: left;
    padding: 50px;
    text-transform: capitalize;
}
.Footer-title{
    font-size: 12px;
    font-weight: bolder;
    letter-spacing: .3em;
    line-height: .13636364em;
    text-transform: uppercase;
}
.Footer-description{
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .02em;
}
.Footer-address{
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .02em;
}
.Footer-sub-title{
    font-size: 10px;
    letter-spacing: .3em;
    line-height: .13636364em;
    text-transform: uppercase;
}

.Footer-google img{
    max-height: 40px;
    padding: 2px 5px;
}
.Footer-facebook img, .Footer-youtube img, .Footer-instagram img{
    max-width: 40px;
    padding: 2px 5px;
}
.Ditenun-not-found{
    padding: 20% 0;
}
.Ditenun-product, .Product-detail {
    margin: 20px 0 20px 0;
    padding: 0 10px;
}
.Reset, .Reset a{
    background-color: #fff;
    color: #333;
    text-align: center;
    border-radius: 0;
}
.Ditenun-modal {
    text-transform: capitalize;
    text-align: center;
}
.Ditenun-modal h1 {
    font-size: 25px;
    padding-top: 50px;
}
.Ditenun-modal label {

    font-size: 10px;
    margin: 0;
}
.Ditenun-modal button {
    width: auto;
}
.Left{
    text-align: left;
}
.Center{
    text-align: center;
}

.Ditenun-list-cart, .Ditenun-list-cart h4{
    text-align: left;
    font-size: 15px;
    padding: 10px;
}
.generate{
    max-width: 125px;
    max-height: 125px;
    padding: 5px;
}
.Random-motif img{
    max-width: 500px;
    max-height: 500px;
    padding: 10px;
}
.List-cart{
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}

.Ditenun-list-cart img{
    max-width: 125px;
    padding: 5px;
}

.Ditenun-list-cart img.img_profile {
    max-width: 50px;
}
.Ditenun-account img{
    max-width: 100px
}
.Motif img{
    padding: 10px;
    max-width: 300px;
}
.Center{
    text-align: center;
}

.rolling {
    top: 50%;
    right: 50%;
    position: absolute;
}

@media screen and (max-width:600px) {
    .Mobile-hidden{
      display: none;
    }
    .container {
        text-align: left;
        display: block;
        width:100%;
        padding: 40px 40px;
        font-size: 15px;
    }
    h1 {
      font-size: 25px;
    }
    .Jumbotron, .Service, .Footer, .Category-row-menu {
      width:100%;
      padding: 0;

    }
    .Jumbotron-description {
        padding: 10px 0;
    }
    .Jumbotron-description h1 {
        padding: 10px;
        margin: 0;
    }
    .Service-title {
        padding: 0;
    }

    .Ditenun2 a {
        font-size: 9px;
        letter-spacing: 0;
    }
    .Header button {
        color: #333;
        width: auto;
    }
    .Service-content {
        width: auto;
        padding: 3px;
    }
    .Footer-description{
        letter-spacing: 0;
    }
    .Category-row-menu {
        padding: 0;
    }
    .Category-row-menu button {
        width: 100%;
        font-size: 10px;
    }
    .Ditenun-product{
        width:100%;
        margin: auto;
        padding: 5px 30px;
    }
    .Category-menu .container {
        padding: 0;
        margin: auto;
    }
    .Product-list{
        padding: 0;
    }
    .Ditenun-product p{
        font-size: 10px;
    }
    .Ditenun-product .Category-menu .container {
        padding: 0;
    }
    .Product-detail button{
        width:100%;
        margin: auto;
        width: auto;
    }
    .Random-motif img{
        max-width: 100%;
    }
  }
  @media screen and (min-width:601px) and (max-width:800px) {
    .Tab-hidden{
        display: none;
      }
    .container {
        text-align: left;
        display: block;
        width:100%;
        padding: 20px 0;
        font-size: 15px;
        margin: auto;
    }
    h1 {
      font-size: 25px;
    }
    .Jumbotron, .Service, .Footer {
      width:100%;
      padding: 0;

    }
    .Jumbotron-description {
        padding: 10px 0;
    }
    .Jumbotron-description h1 {
        padding: 10px;
        margin: 0;
    }
    .Service-title {
        padding: 0;
    }
    .Ditenun2 a {
        font-size: 9px;
        letter-spacing: 0;
    }
    .Service-content {
        width: auto;
        padding: 3px;
    }
    .Footer-description{
        letter-spacing: 0;
    }
  }
